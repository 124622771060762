import { __extends } from "tslib";
import React from 'react';
import { ContextMoreIcon } from '../icons/ContextMoreIcon';
import { Popover } from './Popover';
import S from './ContextMenuButton.styl';
var ContextMenuButton = /** @class */ (function (_super) {
    __extends(ContextMenuButton, _super);
    function ContextMenuButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            popoverOpen: false,
        };
        _this.onClick = function (event) {
            if (_this.state.popoverOpen) {
                _this.setState({ popoverOpen: false });
                return;
            }
            var bounds = event.currentTarget.getBoundingClientRect();
            _this.setState({
                popoverOpen: true,
                popoverTarget: {
                    top: bounds.top + 14,
                    left: bounds.left + 14,
                    width: bounds.width - 28,
                    height: bounds.height - 28,
                },
            });
        };
        _this.onClose = function () {
            _this.setState({ popoverOpen: false });
        };
        return _this;
    }
    ContextMenuButton.prototype.render = function () {
        var _a = this, props = _a.props, state = _a.state;
        var color = props.color;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: S.contextMenuButton + " " + (props.className || ''), type: "button", onClick: this.onClick },
                React.createElement(ContextMoreIcon, { color: color ? color : 'black', className: S.contextMenuButtonIcon })),
            state.popoverOpen &&
                React.createElement(Popover, { target: state.popoverTarget, arrowSide: "top", onRequestClose: this.onClose }, props.children)));
    };
    return ContextMenuButton;
}(React.PureComponent));
export { ContextMenuButton };
