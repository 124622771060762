import { __assign, __decorate, __extends, __rest } from "tslib";
import { inject } from 'mobx-react';
import Qs from 'qs';
import React from 'react';
import { backHistory, pushHistory, replaceHistory } from '../router/actions';
import { Route } from '../router/routes';
export { Route };
var LinkBase = /** @class */ (function (_super) {
    __extends(LinkBase, _super);
    function LinkBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function (event) {
            var props = _this.props;
            var to = props.to, historyType = props.historyType, onClick = props.onClick;
            var clickResult = onClick ? onClick(event) : null;
            if (event.defaultPrevented || props.target) {
                return;
            }
            else if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) {
                return;
            }
            else if (event.buttons != null && event.buttons !== 0 && event.buttons !== 1) {
                return; // not a left click
            }
            event.preventDefault();
            if (clickResult === false) {
                return;
            }
            else if ((!historyType || historyType === 'push') && to) {
                pushHistory(_this.props.store, to, props.query);
            }
            else if (historyType === 'replace' && to) {
                replaceHistory(_this.props.store, to, props.query);
            }
            else if (historyType === 'back') {
                backHistory(_this.props.store);
            }
            else {
                console.error('Only \'push\', \'replace\' and \'back\' are supported as historyTypes');
            }
        };
        return _this;
    }
    LinkBase.prototype.render = function () {
        var _a = this.props, store = _a.store, forwardedRef = _a.forwardedRef, to = _a.to, query = _a.query, historyType = _a.historyType, onClick = _a.onClick, props = __rest(_a, ["store", "forwardedRef", "to", "query", "historyType", "onClick"]);
        if (historyType === 'back') {
            return React.createElement("a", __assign({ ref: forwardedRef, href: "javascript:history.back()" }, props, { onClick: this.onClick }));
        }
        else {
            var href = to && store.history.createHref({
                pathname: to,
                search: query ? '?' + Qs.stringify(query) : undefined,
            });
            return React.createElement("a", __assign({ ref: forwardedRef, href: href }, props, { onClick: this.onClick }));
        }
    };
    LinkBase = __decorate([
        inject('store')
    ], LinkBase);
    return LinkBase;
}(React.PureComponent));
export { LinkBase };
export var Link = React.forwardRef(function (props, ref) { return (React.createElement(LinkBase, __assign({}, props, { forwardedRef: ref }))); });
export var BackLink = React.forwardRef(function (props, ref) { return (React.createElement(LinkBase, __assign({}, props, { forwardedRef: ref, historyType: "back" }))); });
