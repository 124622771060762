import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import S from './Alert.styl';
import { Button } from './Button';
import { Modal } from './Modal';
export var AlertCloseType;
(function (AlertCloseType) {
    AlertCloseType[AlertCloseType["Close"] = 0] = "Close";
    AlertCloseType[AlertCloseType["Confirm"] = 1] = "Confirm";
    AlertCloseType[AlertCloseType["Cancel"] = 2] = "Cancel";
})(AlertCloseType || (AlertCloseType = {}));
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onCloseAction = function () {
            if (_this.props.onClose) {
                _this.props.onClose(AlertCloseType.Close);
            }
        };
        _this.onCancelAction = function () {
            if (_this.props.onClose) {
                _this.props.onClose(AlertCloseType.Cancel);
            }
        };
        _this.onConfirmAction = function () {
            if (_this.props.onClose) {
                _this.props.onClose(AlertCloseType.Confirm);
            }
        };
        return _this;
    }
    Alert.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        var alertMessage = '' + props.alertMessage;
        alertMessage = alertMessage.replace('Error: ', '');
        return (React.createElement(Modal, { onRequestClose: this.onCloseAction },
            React.createElement("div", { className: S.alertModalView },
                React.createElement("div", { className: S.alertModalTitleText }, props.alertTitle ? props.alertTitle : ''),
                React.createElement("div", { className: S.alertModalTextContent }, alertMessage),
                props.children,
                props.alertNeedsConfirm !== true ?
                    React.createElement("div", { className: S.alertModalFooter },
                        React.createElement(Button, { className: "secondaryButton " + S.alertModalConfirm, onClick: this.onCloseAction }, i18n.alertModalOk))
                    :
                        React.createElement("div", { className: S.alertModalFooter },
                            React.createElement(Button, { className: "secondaryButton " + S.alertModalCancel, onClick: this.onCancelAction }, i18n.alertModalCancel),
                            React.createElement(Button, { className: "secondaryButton " + S.alertModalConfirm, onClick: this.onConfirmAction }, i18n.alertModalConfirm)))));
    };
    Alert = __decorate([
        inject('store'),
        observer
    ], Alert);
    return Alert;
}(React.Component));
export { Alert };
