import { del, EndPoints, get, post } from '../';
export function list() {
    return get(EndPoints.faq, { limit: 100 });
}
export function createFaq(request) {
    return post(EndPoints.faqCreate, request);
}
export function updateFaq(request) {
    return post(EndPoints.faqUpdate, request);
}
export function positionFaq(id, position) {
    return post(EndPoints.faqPosition, { id: id, position: position });
}
export function deleteFaq(faqId) {
    return del(EndPoints.faqDelete, { faqId: faqId });
}
