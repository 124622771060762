import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { LoginResponse } from './types';
import { AuthLayout } from './AuthLayout';
import { LoginView } from './LoginView';
var Authenticator = /** @class */ (function (_super) {
    __extends(Authenticator, _super);
    function Authenticator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Authenticator.prototype.render = function () {
        var props = this.props;
        var loginStatus = props.store.loginStatus;
        if (loginStatus !== LoginResponse.Success) {
            return React.createElement(AuthLayout, { isWhiteBackground: true, children: React.createElement(LoginView, null) });
        }
        return this.props.children;
    };
    Authenticator = __decorate([
        inject('store'),
        observer
    ], Authenticator);
    return Authenticator;
}(React.Component));
export { Authenticator };
