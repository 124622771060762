export var Color;
(function (Color) {
    Color["Primary"] = "#8F93C4";
    Color["PrimaryLight"] = "#8F93C4";
    Color["LightGray"] = "#8a8a8a";
    Color["TextLightGray"] = "#949ca9";
})(Color || (Color = {}));
var ZEROES = '000000';
export function numberToHexColor(color) {
    var hex = color.toString(16);
    if (hex.length < 6) {
        hex = ZEROES.substring(0, 6 - hex.length) + hex;
    }
    return '#' + hex;
}
export function numberToRGBA(color, alpha) {
    /* tslint:disable no-bitwise */
    var r = (color >> 16) & 0xFF;
    var g = (color >> 8) & 0xFF;
    var b = (color) & 0xFF;
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}
export function blend(top, bottom, alpha) {
    /* tslint:disable no-bitwise */
    var r = ((((top >> 16) & 0xFF) * alpha) + (((bottom >> 16) & 0xFF) * (1 - alpha))) & 0xFF;
    var g = ((((top >> 8) & 0xFF) * alpha) + (((bottom >> 8) & 0xFF) * (1 - alpha))) & 0xFF;
    var b = ((((top) & 0xFF) * alpha) + (((bottom) & 0xFF) * (1 - alpha))) & 0xFF;
    return (r << 16) | (g << 8) | b;
}
export function isLightColor(hexcolor) {
    return (hexcolor.indexOf('feeb9a') !== -1);
}
