import { __extends } from "tslib";
import React from 'react';
import S from './Popover.styl';
var ARROW_CLASSES = {
    top: S.popoverFrameTopArrow,
    right: S.popoverFrameRightArrow,
    bottom: S.popoverFrameBottomArrow,
    left: S.popoverFrameLeftArrow,
};
var POSITION_CLASSES = {
    start: S.popoverFrameStartArrow,
    center: S.popoverFrameCenterArrow,
    end: S.popoverFrameEndArrow,
};
var Popover = /** @class */ (function (_super) {
    __extends(Popover, _super);
    function Popover() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            arrowSide: _this.props.arrowSide,
        };
        _this.onWindowClick = function (event) {
            var onRequestClose = _this.props.onRequestClose;
            if (!onRequestClose) {
                return;
            }
            var el = event.target;
            while (el) {
                if (el instanceof Element && el.classList && el.classList.contains(S.popover)) {
                    // the click was inside the popover
                    return;
                }
                el = el.parentNode;
            }
            onRequestClose();
        };
        return _this;
    }
    Popover.prototype.componentDidMount = function () {
        var _a = this.props, target = _a.target, arrowSide = _a.arrowSide, arrowPosition = _a.arrowPosition;
        window.addEventListener('click', this.onWindowClick, true);
        if (target) {
            this.reposition(target, arrowSide, arrowPosition);
        }
    };
    Popover.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, target = _a.target, arrowSide = _a.arrowSide, arrowPosition = _a.arrowPosition;
        if (target && (target !== prevProps.target || arrowSide !== prevProps.arrowSide || arrowPosition !== prevProps.arrowPosition)) {
            this.reposition(target, arrowSide, arrowPosition);
        }
    };
    Popover.prototype.componentWillUnmount = function () {
        window.removeEventListener('click', this.onWindowClick, true);
    };
    Popover.prototype.reposition = function (target, arrowSide, arrowPosition) {
        var topSpace = target.top;
        var leftSpace = target.left;
        var rightSpace = window.innerWidth - (target.left + target.width);
        var bottomSpace = window.innerHeight - (target.top + target.height);
        if (!arrowSide) {
            var spaceForArrowSides = [
                ['bottom', topSpace],
                ['right', leftSpace],
                ['left', rightSpace],
                ['top', bottomSpace],
            ];
            spaceForArrowSides.sort(function (a, b) { return b[1] - a[1]; });
            arrowSide = spaceForArrowSides[0][0];
        }
        switch (arrowSide) {
            case 'left':
                this.setState({
                    arrowSide: arrowSide,
                    arrowPosition: arrowPosition || ((topSpace < bottomSpace) ? 'start' : 'end'),
                    top: target.top + target.height * 0.5,
                    left: target.left + target.width,
                });
                break;
            case 'right':
                this.setState({
                    arrowSide: arrowSide,
                    arrowPosition: arrowPosition || ((topSpace < bottomSpace) ? 'start' : 'end'),
                    top: target.top + target.height * 0.5,
                    left: target.left,
                });
                break;
            case 'top':
                this.setState({
                    arrowSide: arrowSide,
                    arrowPosition: arrowPosition || ((leftSpace < rightSpace) ? 'start' : 'end'),
                    top: target.top + target.height,
                    left: target.left + target.width * 0.5,
                });
                break;
            case 'bottom':
                this.setState({
                    arrowSide: arrowSide,
                    arrowPosition: arrowPosition || ((leftSpace < rightSpace) ? 'start' : 'end'),
                    top: target.top,
                    left: target.left + target.width * 0.5,
                });
                break;
        }
    };
    Popover.prototype.render = function () {
        var _a = this, props = _a.props, state = _a.state;
        var _b = this.state, left = _b.left, top = _b.top;
        return (React.createElement("div", { className: S.popover + " " + (props.className || ''), style: { left: left, top: top } },
            React.createElement("div", { className: S.popoverFrame + " " + ARROW_CLASSES[state.arrowSide] + " " + POSITION_CLASSES[state.arrowPosition] + " " + (props.frameClass || '') },
                React.createElement("div", { className: S.popoverBox + " " + (props.boxClass || '') }, props.children))));
    };
    return Popover;
}(React.PureComponent));
export { Popover };
