import { __extends } from "tslib";
import classNames from 'classnames';
import React from 'react';
import { Color } from '../utils/color';
import { closestParent } from '../utils/dom';
import S from './Dropdown.styl';
function DropdownList(props) {
    return (React.createElement("ul", { className: S.dropdownList + " " + (props.className || '') }, props.children));
}
var DropdownItem = /** @class */ (function (_super) {
    __extends(DropdownItem, _super);
    function DropdownItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DropdownItem.prototype.render = function () {
        var props = this.props;
        return (React.createElement("li", { className: S.dropdownItem + " " + (props.itemClass || '') + " " + ((props.autoClose === false) ? '' : 'dropdownItem--autoClose') },
            React.createElement("button", { className: S.dropdownItemButton, type: "button", "data-item": props.data, onClick: props.onClick, onKeyDown: onDropdownItemKeyDown },
                React.createElement("div", { className: S.dropdownItemButtonContent },
                    (props.icon !== undefined) &&
                        React.createElement("span", { className: S.dropdownItemIcon }, props.icon),
                    React.createElement("span", { className: S.dropdownItemContent + " " + (props.contentClass || '') }, props.children)))));
    };
    return DropdownItem;
}(React.PureComponent));
var Dropdown = /** @class */ (function (_super) {
    __extends(Dropdown, _super);
    function Dropdown() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            open: false,
        };
        _this.ref = null;
        _this.onLabelClick = function () {
            _this.toggle();
        };
        _this.onOpenMaybe = function () {
            if (_this.props.onOpen && _this.state.open) {
                _this.props.onOpen();
            }
        };
        _this.onWindowClick = function (event) {
            var ref = _this.ref;
            var el = event.target;
            while (el) {
                if (el === ref) {
                    // the click was inside the dropdown but not on an autoClose item
                    return;
                }
                if (el instanceof HTMLElement && el.classList.contains('dropdownItem--autoClose')) {
                    break;
                }
                el = el.parentNode;
            }
            _this.close();
        };
        return _this;
    }
    Dropdown.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.state.open !== prevState.open) {
            if (this.state.open) {
                window.addEventListener('click', this.onWindowClick, true);
            }
            else {
                window.removeEventListener('click', this.onWindowClick, true);
            }
        }
    };
    Dropdown.prototype.componentWillUnmount = function () {
        window.removeEventListener('click', this.onWindowClick, true);
    };
    Dropdown.prototype.open = function () {
        this.setState({ open: true }, this.onOpenMaybe);
    };
    Dropdown.prototype.close = function () {
        this.setState({ open: false });
    };
    Dropdown.prototype.toggle = function (callback) {
        this.setState({
            open: !this.state.open,
            boxProps: this.ref ? this.ref.getBoundingClientRect() : undefined,
        }, this.onOpenMaybe);
    };
    Dropdown.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this, props = _b.props, state = _b.state;
        var label = props.label, title = props.title;
        var boxProps = state.boxProps;
        var style = props.isFixed === true ? { position: 'fixed', width: boxProps && boxProps.width, top: boxProps && boxProps.top + boxProps.height, maxHeight: props.maxHeight || 450 } : {};
        return (React.createElement("div", { ref: function (c) { return _this.ref = c; }, className: classNames(S.dropdown, props.className, (_a = {}, _a[S.dropdownOpen + " " + (props.openClass || '')] = state.open, _a)) + ' ' + (title && S.dropdownWithTitle) },
            React.createElement("div", { className: S.dropdownBox + " " + (props.boxClass || '') },
                title &&
                    React.createElement("p", { className: S.dropdownTitle }, title),
                React.createElement("button", { className: S.dropdownLabel + " dropDownLabel " + ((label !== undefined) ? '' : S.dropdownPlaceholder) + " " + (props.labelClass || ''), type: "button", title: typeof label === 'string' ? label : undefined, disabled: props.isDisabled === true, onClick: this.onLabelClick },
                    (label !== undefined) ?
                        label
                        :
                            (props.placeholder || null),
                    React.createElement("svg", { className: S.dropdownArrow + " " + (props.arrowClass || '') + " dropDownArrow", width: "10", height: "4.6", viewBox: "0 0 12 6" },
                        React.createElement("path", { fill: Color.Primary, d: "M.982.07L0 .903 5.84 6 12 .85 11.039 0 5.86 4.33z" }))),
                React.createElement("div", { className: S.dropdownContent + " " + (props.contentClass || ''), style: style }, props.children))));
    };
    Dropdown.List = DropdownList;
    Dropdown.Item = DropdownItem;
    return Dropdown;
}(React.PureComponent));
export { Dropdown };
function onDropdownItemKeyDown(event) {
    var keyCode = event.keyCode, currentTarget = event.currentTarget;
    if (keyCode !== 38 && keyCode !== 40) {
        return;
    }
    var parent = closestParent(currentTarget.parentNode, '.dropdownList');
    if (!(parent instanceof HTMLElement)) {
        return;
    }
    event.preventDefault();
    var siblings = parent.querySelectorAll('.dropdownItemButton');
    for (var i = 0; i < siblings.length; ++i) {
        if (siblings[i] === currentTarget) {
            if (keyCode === 38 && i > 0) {
                siblings[i - 1].focus();
            }
            else if (keyCode === 40 && i < siblings.length - 1) {
                siblings[i + 1].focus();
            }
        }
    }
}
