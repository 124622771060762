import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
// import {pushHistory} from '../router/actions';
// import {Route} from '../router/routes';
import S from './settings.styl';
export var SettingsPage;
(function (SettingsPage) {
    SettingsPage[SettingsPage["CompanyDetails"] = 1] = "CompanyDetails";
    SettingsPage[SettingsPage["Teams"] = 2] = "Teams";
    SettingsPage[SettingsPage["Positions"] = 3] = "Positions";
    SettingsPage[SettingsPage["Locations"] = 4] = "Locations";
    SettingsPage[SettingsPage["Roles"] = 5] = "Roles";
})(SettingsPage || (SettingsPage = {}));
var SettingsView = /** @class */ (function (_super) {
    __extends(SettingsView, _super);
    function SettingsView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChangeTabBar = function (page) {
            /*if (page === this.props.currentPage) {
                return;
            } else if (page === SettingsPage.CompanyDetails) {
                pushHistory(this.props.store!, Route.SettingsCompanyDetails);
            } else if (page === SettingsPage.Teams) {
                pushHistory(this.props.store!, Route.SettingsTeams);
            } else if (page === SettingsPage.Positions) {
                pushHistory(this.props.store!, Route.SettingsPositions);
            } else if (page === SettingsPage.Locations) {
                pushHistory(this.props.store!, Route.SettingsLocations);
            } else {
                pushHistory(this.props.store!, Route.SettingsRoles);
            }*/
        };
        return _this;
    }
    SettingsView.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        return (React.createElement("div", { className: S.settingsView },
            React.createElement("h1", { className: S.settingsTitle }, i18n.settingsTitle),
            React.createElement("hr", { className: S.settingsSeparator }),
            React.createElement("div", { className: S.settingsMainBox })));
    };
    SettingsView = __decorate([
        inject('store'),
        observer
    ], SettingsView);
    return SettingsView;
}(React.Component));
export { SettingsView };
