import { __assign, __rest } from "tslib";
import React from 'react';
import { Spinner } from '../components/Spinner';
import S from './Button.styl';
export var Button = React.forwardRef(function (_a, ref) {
    var spinner = _a.spinner, _b = _a.disabled, disabled = _b === void 0 ? !!spinner : _b, props = __rest(_a, ["spinner", "disabled"]);
    return ((spinner ?
        React.createElement("button", __assign({ type: props.type, ref: ref, disabled: disabled }, props),
            React.createElement(Spinner, { className: S.spinner, width: 22, height: 22, color: (typeof spinner === 'string') ? spinner : 'white' }))
        :
            React.createElement("button", __assign({ ref: ref, disabled: disabled }, props))));
});
