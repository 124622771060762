import { __assign, __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Modal } from '../components/Modal';
import { setLocale } from '../i18n/actions';
import { Button } from '../components/Button';
import S from './AuthModal.styl';
var AuthModal = /** @class */ (function (_super) {
    __extends(AuthModal, _super);
    function AuthModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.switchLocale = function () {
            var store = _this.props.store;
            setLocale(store, store.i18n.otherLocale);
        };
        return _this;
    }
    AuthModal.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { className: "secondaryButton " + S.authModalLangagePicker, type: "button", onClick: this.switchLocale }, i18n.otherLocale.toUpperCase()),
            React.createElement(Modal, __assign({}, props),
                React.createElement("div", { className: S.authModalBox }, this.props.children))));
    };
    AuthModal = __decorate([
        inject('store'),
        observer
    ], AuthModal);
    return AuthModal;
}(React.Component));
export { AuthModal };
