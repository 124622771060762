import React from 'react';
import { Color } from '../utils/color';
import S from './Spinner.styl';
export var Spinner = function (_a) {
    var className = _a.className, _b = _a.width, width = _b === void 0 ? 50 : _b, _c = _a.height, height = _c === void 0 ? 50 : _c, _d = _a.color, color = _d === void 0 ? Color.Primary : _d;
    return (React.createElement("svg", { className: S.spinner + " " + (className || ''), width: width, height: height, viewBox: "0 0 32 32", fill: color },
        React.createElement("path", { opacity: ".25", d: "M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4" }),
        React.createElement("path", { d: "M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z" },
            React.createElement("animateTransform", { attributeName: "transform", type: "rotate", from: "0 16 16", to: "360 16 16", dur: "0.8s", repeatCount: "indefinite" }))));
};
export var SpinnerBlock = function () {
    return (React.createElement("div", { className: S.spinnerBlock },
        React.createElement(Spinner, { width: 25, height: 25 })));
};
export var SpinnerOverlay = function () {
    return (React.createElement("div", { className: S.spinnerOverlay },
        React.createElement("div", { className: S.spinnerOverlayLimit },
            React.createElement(Spinner, null))));
};
