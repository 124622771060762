import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import S from './FeedItem.styl';
var FeedItem = /** @class */ (function (_super) {
    __extends(FeedItem, _super);
    function FeedItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FeedItem.prototype.render = function () {
        var props = this.props;
        var feed = props.feed;
        return (React.createElement("div", { className: S.feedItem },
            React.createElement("div", { className: S.feedImage },
                React.createElement("img", { src: feed.imageUrl, width: "160", height: "80" })),
            React.createElement("div", { className: S.feedContent },
                feed.title,
                React.createElement("br", null),
                feed.preview)));
    };
    FeedItem = __decorate([
        inject('store'),
        observer
    ], FeedItem);
    return FeedItem;
}(React.Component));
export { FeedItem };
