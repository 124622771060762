import { __decorate, __extends } from "tslib";
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { sendPush } from '../../api/push';
import { Button } from '../../components/Button';
import { customConfirm } from '../../utils/utils';
import S from './pushNotifications.styl';
var PushNotificationsView = /** @class */ (function (_super) {
    __extends(PushNotificationsView, _super);
    function PushNotificationsView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            titleInputError: false,
            contentInputError: false,
        };
        _this.onChangeAction = function (e) {
            var name = e.currentTarget.name;
            if (name === 'title') {
                _this.setState({ titleInputError: false });
            }
            if (name === 'content') {
                _this.setState({ contentInputError: false });
            }
        };
        return _this;
    }
    PushNotificationsView.prototype.onSubmit = function (event) {
        var _this = this;
        var i18n = this.props.store.i18n;
        event.preventDefault();
        var elements = event.currentTarget.elements;
        var valid = true;
        var title = elements.title.value;
        var content = elements.content.value;
        if (title.length === 0) {
            this.setState({ titleInputError: true });
            valid = false;
        }
        if (content.length === 0) {
            this.setState({ contentInputError: true });
            valid = false;
        }
        if (!valid) {
            return;
        }
        var request = {
            title: title,
            content: content,
        };
        customConfirm(i18n.pushNotificationsConfirm, this.props.store, function (success) {
            if (success) {
                _this.setState({ loading: true });
                sendPush(request).then(function () {
                    _this.setState({ loading: false });
                    elements.title.value = '';
                    elements.content.value = '';
                }).catch(function (error) {
                    _this.setState({ loading: false });
                    alert(error);
                });
            }
        });
    };
    PushNotificationsView.prototype.render = function () {
        var props = this.props;
        var loading = this.state.loading;
        var i18n = props.store.i18n;
        return (React.createElement("div", { className: S.pushNotificationsView },
            React.createElement("h1", { className: S.pushNotificationsTitle }, i18n.pushNotificationsTitle),
            React.createElement("hr", { className: S.pushNotificationsSeparator }),
            React.createElement("div", { className: S.pushNotificationsMainBox },
                React.createElement("p", { className: S.pushHeaderText }, i18n.pushNotificationSendTitle),
                React.createElement("form", { className: "" + S.addDialogContent, method: "get", action: "/", onSubmit: this.onSubmit, noValidate: true },
                    React.createElement("div", null,
                        React.createElement("div", { className: S.addInputWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.faqFormTitle),
                            React.createElement("input", { className: S.addInput + " textInput", name: "title", onInput: this.onChangeAction, placeholder: i18n.faqFormTitle })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.titleInputError ? 'inputErrorActive' : '') }, i18n.validationTitleError),
                        React.createElement("div", { className: S.addTextareaWrapper },
                            React.createElement("label", { className: S.addInputTitle }, i18n.faqFormContent),
                            React.createElement("textarea", { className: S.addInput + " textInput", name: "content", onInput: this.onChangeAction, placeholder: i18n.faqFormContent })),
                        React.createElement("p", { className: "inputError " + S.addInputError + " " + (this.state.contentInputError ? 'inputErrorActive' : '') }, i18n.validationContentError)),
                    React.createElement(Button, { className: "primaryButton fullwidthButton " + S.pushModalButton, type: "submit", spinner: loading }, i18n.actionSend)))));
    };
    __decorate([
        action.bound
    ], PushNotificationsView.prototype, "onSubmit", null);
    PushNotificationsView = __decorate([
        inject('store'),
        observer
    ], PushNotificationsView);
    return PushNotificationsView;
}(React.Component));
export { PushNotificationsView };
