import { __extends } from "tslib";
import classNames from 'classnames';
import React from 'react';
import { makeId } from '../utils/utils';
import { ContextMenuButton } from '../components/ContextMenuButton';
import { CrossIcon } from '../icons/CrossIcon';
import S from './Modal.styl';
var ModalId = React.createContext(undefined);
var ModalTitleBar = /** @class */ (function (_super) {
    __extends(ModalTitleBar, _super);
    function ModalTitleBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ModalTitleBar.prototype.render = function () {
        var props = this.props;
        var onClose = props.onClose, _a = props.actions, actions = _a === void 0 ? null : _a;
        return (React.createElement(ModalId.Consumer, null, function (titleId) { return (React.createElement("div", { className: S.modalTitleBar },
            React.createElement("h1", { className: S.modalTitleBarText, id: props.id || titleId }, props.children),
            !!actions &&
                React.createElement(ContextMenuButton, { className: S.modalTitleActionsButton, color: 'white' }, actions),
            !!onClose &&
                React.createElement("button", { className: S.modalTitleCloseButton, type: "button", onClick: onClose },
                    React.createElement(CrossIcon, { className: S.modalTitleCloseIcon, width: 14, height: 14 })))); }));
    };
    return ModalTitleBar;
}(React.PureComponent));
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.defaultTitleId = 'modal-id-' + makeId(8);
        _this.onClick = function (event) {
            var onRequestClose = _this.props.onRequestClose;
            if (onRequestClose && event.target === event.currentTarget) {
                onRequestClose(event);
            }
        };
        return _this;
    }
    Modal.prototype.render = function () {
        var _a;
        var props = this.props;
        var className = classNames(S.modal, props.className, (_a = {},
            _a[S.modalCentered] = props.centered,
            _a[S.modalStatic] = props.position,
            _a));
        var titleId = props.titleId || this.defaultTitleId;
        return (React.createElement(ModalId.Provider, { value: titleId },
            React.createElement("div", { className: className, role: "dialog", "aria-labelledby": titleId },
                React.createElement("div", { className: S.modalTable },
                    React.createElement("div", { className: S.modalCell + " modalCell " + (props.fullScreen ? S.modalFullCellHeight : ''), onClick: props.onRequestClose ? this.onClick : undefined }, props.children)))));
    };
    Modal.TitleBar = ModalTitleBar;
    return Modal;
}(React.PureComponent));
export { Modal };
