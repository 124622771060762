import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { routeDidChange } from './handlers';
var Router = /** @class */ (function (_super) {
    __extends(Router, _super);
    function Router() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Router.prototype.componentDidMount = function () {
        var _this = this;
        var history = this.props.store.history;
        this.unsubscribeHistory = history.listen(function (location, action) {
            routeDidChange(_this.props.store, location, action, location.state);
        });
        routeDidChange(this.props.store, history.location);
    };
    Router.prototype.componentWillUnmount = function () {
        if (this.unsubscribeHistory) {
            this.unsubscribeHistory();
        }
    };
    Router.prototype.render = function () {
        return this.props.children;
    };
    Router = __decorate([
        inject('store'),
        observer
    ], Router);
    return Router;
}(React.Component));
export { Router };
