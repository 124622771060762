import { del, EndPoints, get, post } from '../';
export function list() {
    return get(EndPoints.users, null);
}
export function createUser(request) {
    return post(EndPoints.usersCreate, request);
}
export function deleteUser(request) {
    return del(EndPoints.usersDelete, request);
}
