import { action, runInAction } from 'mobx';
import Qs from 'qs';
import ReactGA from 'react-ga';
import { Route } from './routes';
export { Route };
export var pushHistory = action(function (store, pathname, query, state) {
    if (store.confirmUnload) {
        store.confirmHistory = confirmHistory;
    }
    else {
        confirmHistory();
    }
    function confirmHistory() {
        runInAction(function () {
            store.confirmHistory = undefined;
        });
        ReactGA.pageview(pathname);
        store.history.push(addQuery(pathname, query), state);
    }
});
export var replaceHistory = action(function (store, pathname, query, state) {
    if (store.confirmUnload) {
        store.confirmHistory = confirmHistory;
    }
    else {
        confirmHistory();
    }
    function confirmHistory() {
        runInAction(function () {
            store.confirmHistory = undefined;
        });
        ReactGA.pageview(pathname);
        store.history.replace(addQuery(pathname, query), state);
    }
});
export var backHistory = action(function (store) {
    if (store.confirmUnload) {
        store.confirmHistory = confirmHistory;
    }
    else {
        confirmHistory();
    }
    function confirmHistory() {
        runInAction(function () {
            store.confirmHistory = undefined;
        });
        store.history.goBack();
    }
});
export var goHistory = action(function (store, n) {
    if (n === void 0) { n = 0; }
    if (store.confirmUnload) {
        store.confirmHistory = confirmHistory;
    }
    else {
        confirmHistory();
    }
    function confirmHistory() {
        runInAction(function () {
            store.confirmHistory = undefined;
        });
        store.history.go(n);
    }
});
export function parseRouteLocation(loc) {
    return { pathname: loc.pathname, query: Qs.parse(loc.search.substring(1)) };
}
function addQuery(url, query) {
    if (query == null) {
        return url;
    }
    if (typeof query !== 'string') {
        query = Qs.stringify(query);
    }
    return "" + url + ((url.indexOf('?') === -1) ? '?' : '&') + query;
}
