import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { deleteFaq } from '../api/faq';
import { customConfirm } from '../utils/utils';
import { Button } from './Button';
import S from './FaqItem.styl';
var FaqItem = /** @class */ (function (_super) {
    __extends(FaqItem, _super);
    function FaqItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.deleteItem = function () {
            var i18n = _this.props.store.i18n;
            var faq = _this.props.faq;
            if (faq) {
                customConfirm(i18n.faqDeleteSummary, _this.props.store, function (success) {
                    if (success) {
                        _this.setState({ loading: true });
                        deleteFaq(faq.id || 0).then(function () {
                            _this.setState({ loading: false });
                            _this.isUpdated();
                        }).catch(function (error) {
                            _this.setState({ loading: false });
                            alert(error);
                        });
                    }
                });
            }
        };
        _this.isUpdated = function () {
            if (_this.props.isUpdated) {
                _this.props.isUpdated();
            }
        };
        _this.updateItem = function () {
            if (_this.props.updateItem) {
                _this.props.updateItem(_this.props.faq);
            }
        };
        _this.positionChanged = function (event) {
            var _a, _b, _c;
            if (_this.props.changePosition) {
                _this.props.changePosition((_b = (_a = _this.props) === null || _a === void 0 ? void 0 : _a.faq) === null || _b === void 0 ? void 0 : _b.id, (_c = event === null || event === void 0 ? void 0 : event.currentTarget) === null || _c === void 0 ? void 0 : _c.value);
            }
        };
        return _this;
    }
    FaqItem.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        var faq = props.faq;
        return (React.createElement("div", { className: S.faqItem }, faq ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: S.title },
                    faq.title,
                    React.createElement("br", null),
                    React.createElement("input", { className: S.position, defaultValue: faq.position, type: "number", onBlur: this.positionChanged })),
                React.createElement("div", { className: S.content },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: faq.content || '' } }),
                    React.createElement("div", { className: S.contentFade })),
                React.createElement("div", { className: S.action },
                    React.createElement(Button, { className: S.deleteButton, type: "button", onClick: this.deleteItem }, i18n.actionDelete),
                    React.createElement(Button, { className: S.updateButton, type: "button", onClick: this.updateItem }, i18n.actionUpdate)))
            :
                React.createElement(React.Fragment, null)));
    };
    FaqItem = __decorate([
        inject('store'),
        observer
    ], FaqItem);
    return FaqItem;
}(React.Component));
export { FaqItem };
