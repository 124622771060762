export var ACTIVE_GROUP_ID = 'ACTIVE_GROUP_ID';
export var GS_TOKEN = 'GS_TOKEN';
export function readCookie(name) {
    var result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(document.cookie);
    return result ? result[1] : null;
}
export function writeCookie(name, value, days) {
    if (days === void 0) { days = 365 * 20; }
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = '; expires=' + date.toUTCString();
    document.cookie = name + '=' + value + '' + expires + '; path=/';
}
export function writeSessionCookie(name, value) {
    document.cookie = name + '=' + value + '; path=/';
}
export function removeCookie(name) {
    writeCookie(name, '', -1);
}
export function getFilterFromCookie(cookie) {
    var content = readCookie(cookie);
    if (content) {
        return JSON.parse(content);
    }
    else {
        return undefined;
    }
}
export function getIntTypeCookie(cookie) {
    var type = readCookie(cookie);
    if (type) {
        return parseInt(type, 10);
    }
    else {
        return 1;
    }
}
export function getBoolTypeCookie(cookie) {
    var type = readCookie(cookie);
    if (type) {
        return parseInt(type, 10) === 1 ? true : false;
    }
    else {
        return false;
    }
}
export function getStringTypeCookie(cookie, defaultValue) {
    var type = readCookie(cookie);
    if (type) {
        return type;
    }
    else {
        return defaultValue || '';
    }
}
