export var Route;
(function (Route) {
    Route["Home"] = "/";
    Route["Login"] = "/login";
    Route["Dashboard"] = "/";
    Route["Admins"] = "/admins";
    Route["Settings"] = "/settings";
    Route["Claims"] = "/claims";
    Route["Customers"] = "/customers";
    Route["CustomersDetails"] = "/customers/details";
    Route["PushNotifications"] = "/pushNotifications";
    Route["Faq"] = "/faq";
    Route["Feed"] = "/rss";
    Route["Documents"] = "/documents";
})(Route || (Route = {}));
