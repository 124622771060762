import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { SideBar } from '../components/SideBar';
import S from './MainLayout.styl';
var MainLayout = /** @class */ (function (_super) {
    __extends(MainLayout, _super);
    function MainLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MainLayout.prototype.render = function () {
        // const {store} = this.props;
        return (React.createElement("div", { className: S.mainLayout },
            React.createElement(SideBar, { className: S.mainLayoutSideBar, activeItem: this.props.activeItem }),
            React.createElement("div", { className: S.mainLayoutMain, id: "mainLayoutMain" },
                React.createElement("div", { className: "" + S.mainLayoutContent }, this.props.children))));
    };
    MainLayout = __decorate([
        inject('store'),
        observer
    ], MainLayout);
    return MainLayout;
}(React.Component));
export { MainLayout };
