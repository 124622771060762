import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { list, parse } from '../../api/feed';
import { Button } from '../../components/Button';
import { FeedItem } from '../../components/FeedItem';
import { SpinnerBlock } from '../../components/Spinner';
import S from './feed.styl';
var FeedView = /** @class */ (function (_super) {
    __extends(FeedView, _super);
    function FeedView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            feeds: [],
            loading: false,
        };
        _this.refetchAction = function () {
            _this.setState({ loading: true });
            parse().then(function (_) {
                _this.fetchData();
            }).catch(function (error) {
                alert(error.toString());
                throw error;
            });
        };
        return _this;
    }
    FeedView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    FeedView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        list().then(function (result) {
            _this.setState({
                loading: false,
                feeds: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    FeedView.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        var _a = this.state, loading = _a.loading, feeds = _a.feeds;
        return (React.createElement("div", { className: S.feedView },
            React.createElement("h1", { className: S.feedTitle }, i18n.feedTitle),
            React.createElement("hr", { className: S.feedSeparator }),
            React.createElement("div", { className: S.feedHeaderActions },
                React.createElement(Button, { className: "primaryButton " + S.addButton, type: "button", onClick: this.refetchAction, spinner: loading }, i18n.actionRefresh)),
            React.createElement("div", { className: S.feedMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.feedList }, feeds.map(function (feed, i) { return (React.createElement(FeedItem, { key: i, feed: feed })); }))))));
    };
    FeedView = __decorate([
        inject('store'),
        observer
    ], FeedView);
    return FeedView;
}(React.Component));
export { FeedView };
