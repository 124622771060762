import { __extends } from "tslib";
import React from 'react';
import S from './AuthLayout.styl';
var AuthLayout = /** @class */ (function (_super) {
    __extends(AuthLayout, _super);
    function AuthLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuthLayout.prototype.render = function () {
        var isWhiteBackground = this.props.isWhiteBackground;
        return (React.createElement("div", { className: "" + (isWhiteBackground ? S.authLayoutWhite : S.authLayout) }, this.props.children));
    };
    return AuthLayout;
}(React.PureComponent));
export { AuthLayout };
