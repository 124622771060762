import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import S from './DateSquare.styl';
var DateSquare = /** @class */ (function (_super) {
    __extends(DateSquare, _super);
    function DateSquare() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            month: _this.props.store.i18n.formatShortMonthNames(new Date()),
            day: new Date().getDate(),
        };
        return _this;
    }
    DateSquare.prototype.componentDidMount = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        if (this.props.date) {
            this.setState({
                month: i18n.formatShortMonthNames(this.props.date),
                day: this.props.date.getDate(),
            });
        }
    };
    DateSquare.prototype.render = function () {
        var _a = this, state = _a.state, props = _a.props;
        var className = props.className, size = props.size;
        var month = state.month, day = state.day;
        return (React.createElement("div", { style: { 'width': size, 'height': size }, className: className + " " + S.dateSquareContainer },
            React.createElement("div", { className: S.dateSquareMonth, style: { 'fontSize': (size / 4) } }, month.toUpperCase()),
            React.createElement("div", { className: S.dateSquareDay, style: { 'fontSize': (size / 1.75) } }, day)));
    };
    DateSquare = __decorate([
        inject('store'),
        observer
    ], DateSquare);
    return DateSquare;
}(React.Component));
export { DateSquare };
