import { runInAction } from 'mobx';
export var maxMinutesOnDay = 24 * 60;
export var FILTERGROUPBYTYPE = 'FilterGroupByType';
export var FILTERGROUPBYISACTIVE = 'FilterGroupByIsActive';
export var FILTERGROUPBYPOSITIONISACTIVE = 'FilterGroupByPositionIsActive';
export var FILTERGROUPBYTEAMISACTIVE = 'FilterGroupByTeamIsActive';
export var FILTERGROUPBYLOCATIONISACTIVE = 'FilterGroupByLocationIsActive';
export var MEMBERSORTTYPE = 'MemberSortType';
export var SHOWSCHEDULESTATS = 'ShowScheduleStats';
export var SHOWNOTES = 'ShowNotes';
export var ACTIVESTATSLIST = 'ActiveStatsList';
export var SHOWINTERESTEDSHIFTS = 'ShowInterestedShifts';
export var DRAGEXPLANATIONUSED = 'DragExplanationUsed';
export var SHOWNOSCHEDULEEMPLOYEES = 'ShowNoScheduleEmployees';
export var SHOWAVATARS = 'ShowAvatars';
export var SHOWLOCATIONS = 'ShowLocations';
export function isValidEmailFormat(email) {
    // const emailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
        return false;
    }
    return true;
}
export function isValidPhoneFormat(phone) {
    var phoneRegex = /^[\+\d]?(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    if (!phoneRegex.test(phone)) {
        return false;
    }
    return true;
}
export function dataURIToBlob(dataURI) {
    dataURI = dataURI.replace(/^data:/, '');
    var imageType = dataURI.match(/image\/[^;]+/);
    var type = 'image/png';
    if (imageType != null) {
        type = imageType.pop();
    }
    var base64 = dataURI.replace(/^[^,]+,/, '');
    var arrayBuffer = new ArrayBuffer(base64.length);
    var typedArray = new Uint8Array(arrayBuffer);
    for (var i = 0; i < base64.length; i++) {
        typedArray[i] = base64.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: type });
}
export function makeId(length) {
    var text = '';
    var possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
export function escapeCSS(value) {
    return value
        .replace(/\\/g, '\\\\')
        .replace(/\r/g, '\\r')
        .replace(/\n/g, '\\n')
        .replace(/'/g, '\\\'');
}
export function getMinutesFromString(text) {
    var hours = parseInt(text.split(':')[0].replace(/\D/g, ''), 10);
    var minutes = parseInt(text.split(':')[1].replace(/\D/g, ''), 10);
    return (hours * 60) + minutes;
}
export function getTimeFromMinutes(time) {
    var sec = time * 60;
    var hours = Math.floor(sec / 3600);
    var minutes = Math.floor((sec - (hours * 3600)) / 60);
    return ((hours < 10) ? '0' + String(hours) : String(hours)) + ':' + ((minutes < 10) ? '0' + String(minutes) : String(minutes));
}
/* tslint:disable no-bitwise */
export function setBitField(field, bit, on) {
    field = on ? field | bit : field & ~bit;
    return field;
}
/* tslint:disable no-bitwise */
export function isBitSet(field, bit) {
    return (field & bit) === bit;
}
export function getStringNumbers(numberString) {
    var numb = numberString.match(/\d/g);
    if (numb) {
        return numb.join('');
    }
    return '';
}
export function addDaysToDate(date, days) {
    var d = new Date();
    d.setDate(date.getDate() + days);
    return d;
}
export function stringIsNullOrEmpty(str) {
    return str == null || str === '';
}
export function setHours(date) {
    return date.getHours() > 12 ? String(date.getHours() - 12) : String(date.getHours());
}
export function getAmPm(date) {
    return date.getHours() < 12 ? 'AM' : 'PM';
}
export function customConfirm(message, store, callback, title) {
    runInAction(function () {
        store.customConfirmTitle = title;
        confirm(message);
        store.customConfirmCallback = function (success) {
            callback(success);
        };
    });
}
export function isNullOrUndefined(value) {
    return value === undefined || value === null;
}
export function toFixedIfNecessary(value, decimal) {
    return +parseFloat(String(value)).toFixed(decimal);
}
export function toCapFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function roundNumber(num) {
    return Math.round(num * 100) / 100;
}
export function floatToTimeString(time) {
    if (isNaN(time)) {
        return '0h';
    }
    var hours = Math.floor(Math.abs(time));
    var minutes = (Math.floor((Math.abs(time) * 60)) % 60);
    minutes = Math.ceil(minutes / 5) * 5;
    return String(hours) + 'h' + (minutes !== 0 ? ' ' + ((minutes < 10 ? '0' : '') + String(minutes)) + 'm' : '');
}
export function checkIfNumber(num) {
    if (isFinite(num) === false) {
        return undefined;
    }
    else {
        return num.toFixed(2);
    }
}
