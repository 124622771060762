import { configure as configureMobx } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './containers/App';
import { initLocale } from './i18n/actions';
import { Store } from './store';
function initialize() {
    var store = new Store();
    initLocale(store);
    // don't allow state modifications outside actions
    configureMobx({ enforceActions: 'never' });
    ReactDOM.render(React.createElement(Provider, { store: store },
        React.createElement(App, null)), document.getElementById('root'));
}
initialize();
