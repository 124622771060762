import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { list } from '../../api/users';
import { Button } from '../../components/Button';
import { SpinnerBlock } from '../../components/Spinner';
import { UserItem } from '../../components/UserItem';
import { AddAdminModal } from './AddAdminModal';
import S from './admins.styl';
var AdminsView = /** @class */ (function (_super) {
    __extends(AdminsView, _super);
    function AdminsView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            users: [],
            loading: false,
            showCreateModal: false,
        };
        _this.onUpdate = function () {
            _this.fetchData();
        };
        _this.createAction = function () {
            _this.setState({
                showCreateModal: true,
            });
        };
        _this.onCloseAdd = function () {
            _this.setState({
                showCreateModal: false,
            });
        };
        _this.isUpdatedAdd = function () {
            _this.setState({
                showCreateModal: false,
            });
            _this.fetchData();
        };
        return _this;
    }
    AdminsView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    AdminsView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        list().then(function (result) {
            _this.setState({
                loading: false,
                users: result.items,
            });
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    AdminsView.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var i18n = props.store.i18n;
        var _a = this.state, loading = _a.loading, users = _a.users;
        return (React.createElement("div", { className: S.adminsView },
            React.createElement("h1", { className: S.adminsTitle }, i18n.adminsTitle),
            React.createElement("hr", { className: S.adminsSeparator }),
            React.createElement("div", { className: S.adminsHeaderActions },
                React.createElement(Button, { className: "primaryButton " + S.addButton, type: "button", onClick: this.createAction }, i18n.actionCreate)),
            React.createElement("div", { className: S.adminsMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: S.adminsHeader },
                            React.createElement("div", { className: S.email }, i18n.adminsEmail),
                            React.createElement("div", { className: S.firstName }, i18n.adminsFirstName),
                            React.createElement("div", { className: S.lastName }, i18n.adminsLastName),
                            React.createElement("div", { className: S.action }, i18n.adminsAction)),
                        React.createElement("div", { className: S.adminsUserList }, users.map(function (user, i) { return (React.createElement(UserItem, { key: i, user: user, isUpdated: _this.onUpdate })); })))),
            this.state.showCreateModal &&
                React.createElement(AddAdminModal, { onClose: this.onCloseAdd, isUpdated: this.isUpdatedAdd })));
    };
    AdminsView = __decorate([
        inject('store'),
        observer
    ], AdminsView);
    return AdminsView;
}(React.Component));
export { AdminsView };
