export function getEndDay(startOfWeek) {
    var endDay = startOfWeek - 1;
    return endDay < 0 ? 6 : endDay;
}
export function isSameDay(d1, d2) {
    return (d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear());
}
export function isBetween(d1, d2, dBetween) {
    return (!!(d1.getTime() < dBetween.getTime()) && !!(dBetween.getTime() < d2.getTime()));
}
export function getStartOfWeek(d) {
    var day = d.getDay();
    return ((day === 0) ?
        // sunday ; start of week is the monday before
        new Date(d.getFullYear(), d.getMonth(), d.getDate() - 6)
        :
            new Date(d.getFullYear(), d.getMonth(), d.getDate() - day + 1));
}
export function getEndOfWeek(d) {
    var day = d.getDay();
    return ((day === 0) ?
        // sunday ; start of week is the monday before
        new Date(d.getFullYear(), d.getMonth(), d.getDate())
        :
            new Date(d.getFullYear(), d.getMonth(), d.getDate() - day + 7));
}
function getLastWeekday(date, weekday) {
    var d = new Date(date.getTime());
    if (date.getDay() === weekday) {
        return d;
    }
    else if (date.getDay() > weekday) {
        d.setDate(d.getDate() - (d.getDay() - weekday));
        return d;
    }
    else {
        d.setDate(d.getDate() - ((d.getDay() - weekday) + 7));
        return d;
    }
}
export function groupByDay(a, firstDate, fn) {
    var r = [];
    /* tslint:disable:prefer-for-of */
    for (var i = 0; i < a.length; ++i) {
        var item = a[i];
        var date = fn(item);
        if (!date) {
            continue;
        }
        var day = daysBetween(firstDate, date);
        if (day < 0) {
            continue;
        }
        var dayItems = r[day];
        if (dayItems) {
            dayItems.push(item);
        }
        else {
            r[day] = [item];
        }
    }
    return r;
}
export function compareDate(a, b) {
    var yearA = a.getFullYear(), yearB = b.getFullYear();
    if (yearA < yearB) {
        return -1;
    }
    if (yearA > yearB) {
        return 1;
    }
    var monthA = a.getMonth(), monthB = b.getMonth();
    if (monthA < monthB) {
        return -1;
    }
    if (monthA > monthB) {
        return 1;
    }
    var dayA = a.getDate(), dayB = b.getDate();
    if (dayA < dayB) {
        return -1;
    }
    if (dayA > dayB) {
        return 1;
    }
    return 0;
}
export function daysBetween(a, b) {
    var neg = compareDate(b, a) < 0;
    if (neg) {
        var _a = a;
        a = b;
        b = _a;
    }
    var yearA = a.getFullYear();
    var monthA = a.getMonth();
    var count = 0;
    for (;;) {
        var yearB = b.getFullYear();
        var monthB = b.getMonth();
        if (monthB <= monthA && yearB <= yearA) {
            break;
        }
        count += b.getDate();
        b = new Date(yearB, monthB, 0);
    }
    count += (b.getDate() - a.getDate());
    return neg ? -count : count;
}
export function parseYMD(str) {
    var match = /^(\d+)-(\d+)-(\d+)$/.exec(str);
    if (match) {
        return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
    }
}
export function formatYMD(date) {
    var m = date.getMonth() + 1;
    var d = date.getDate();
    return [
        date.getFullYear(),
        (m < 10) ? '-0' : '-', m,
        (d < 10) ? '-0' : '-', d,
    ].join('');
}
export function formatShortTime(d) {
    var h = d.getHours();
    var m = d.getMinutes();
    return [
        h,
        (m < 10) ? ':0' : ':', m,
    ].join('');
}
export function formatTime(h, m) {
    return [
        (h < 10) ? '0' : '', h,
        (m < 10) ? ':0' : ':', m,
    ].join('');
}
export function addMinutesToDate(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}
