import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
import { BASE_DOCUMENT } from '../../api';
import { deleteDocument, list, updateDocument } from '../../api/document';
import { Button } from '../../components/Button';
import SS from '../../components/CustomerItem.styl';
import { SpinnerBlock } from '../../components/Spinner';
import { FileIcon } from '../../icons/FileIcon';
import { DocumentStatus } from '../../models/Document';
import { customConfirm } from '../../utils/utils';
import S from '../documents/documents.styl';
var DocumentsView = /** @class */ (function (_super) {
    __extends(DocumentsView, _super);
    function DocumentsView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stateCount = 0;
        _this.state = {
            documents: [],
            loading: false,
        };
        _this.openDocument = function (document) {
            var url = BASE_DOCUMENT + document.fileName;
            window.open(url, '_blank');
        };
        _this.getDocumentStatus = function (document) {
            var i18n = _this.props.store.i18n;
            if (document.expiryDate) {
                return i18n.documentsStatusExpired;
            }
            else {
                return i18n.documentsStatusItem(document.status || DocumentStatus.pending);
            }
        };
        _this.updateDocument = function (customerId, documentId, status) {
            var i18n = _this.props.store.i18n;
            var title = status === DocumentStatus.approved ? i18n.documentsApproveInfo : i18n.documentsRejectInfo;
            customConfirm(title, _this.props.store, function (success) {
                if (success) {
                    _this.setState({ loading: true });
                    var request = { customerId: customerId, documentId: documentId, status: status };
                    updateDocument(request).then(function () {
                        _this.setState({ loading: false });
                        _this.fetchData();
                    }).catch(function (error) {
                        _this.setState({ loading: false });
                        alert(error);
                    });
                }
            });
        };
        _this.deleteDocument = function (documentId) {
            var i18n = _this.props.store.i18n;
            customConfirm(i18n.alertDeleteDocumentSummary, _this.props.store, function (success) {
                if (success) {
                    _this.setState({ loading: true });
                    var request = { documentId: documentId };
                    deleteDocument(request).then(function () {
                        _this.setState({ loading: false });
                        _this.fetchData();
                    }).catch(function (error) {
                        _this.setState({ loading: false });
                        alert(error);
                    });
                }
            });
        };
        return _this;
    }
    DocumentsView.prototype.componentDidMount = function () {
        var store = this.props.store;
        if (store) {
            this.fetchData();
        }
    };
    DocumentsView.prototype.fetchData = function () {
        var _this = this;
        this.setState({ loading: true });
        list({ type: 'document' }).then(function (result) {
            _this.setState({
                loading: false,
                documents: result.items,
            });
            _this.stateCount++;
        }).catch(function (error) {
            alert(error.toString());
            throw error;
        });
    };
    DocumentsView.prototype.render = function () {
        var _this = this;
        var props = this.props;
        var i18n = props.store.i18n;
        var _a = this.state, loading = _a.loading, documents = _a.documents;
        return (React.createElement("div", { className: S.documentView },
            React.createElement("h1", { className: S.documentTitle }, i18n.documentsTitle),
            React.createElement("hr", { className: S.documentSeparator }),
            React.createElement("div", { className: S.documentMainBox }, loading ?
                React.createElement(SpinnerBlock, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: SS.documentList }, documents === null || documents === void 0 ? void 0 : documents.map(function (document, i) { return (React.createElement("div", { className: SS.documentItem, key: i },
                            React.createElement(FileIcon, { width: 48, height: 48 }),
                            React.createElement("div", { onClick: function (_) { return _this.openDocument(document); }, className: SS.documentName },
                                i18n.customersName,
                                ": ",
                                document.name,
                                React.createElement("br", null),
                                i18n.customersStatus,
                                ": (",
                                _this.getDocumentStatus(document),
                                ")"),
                            React.createElement("div", { className: SS.action }, document.status === DocumentStatus.pending ?
                                React.createElement(React.Fragment, null,
                                    React.createElement(Button, { className: SS.approveButton, type: "button", onClick: function (_) { return _this.updateDocument(document.customerId, document.id, DocumentStatus.approved); } }, i18n.actionApprove),
                                    React.createElement(Button, { className: SS.rejectButton, type: "button", onClick: function (_) { return _this.updateDocument(document.customerId, document.id, DocumentStatus.rejected); } }, i18n.actionReject))
                                :
                                    React.createElement(React.Fragment, null,
                                        React.createElement(Button, { className: SS.deleteButton, type: "button", onClick: function (_) { return _this.deleteDocument(document.id); } }, i18n.actionDelete))))); }))))));
    };
    DocumentsView = __decorate([
        inject('store'),
        observer
    ], DocumentsView);
    return DocumentsView;
}(React.Component));
export { DocumentsView };
