import { __extends } from "tslib";
import React from 'react';
var Await = /** @class */ (function (_super) {
    __extends(Await, _super);
    function Await() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            pending: !!_this.props.source,
        };
        _this.loadingId = {};
        return _this;
    }
    Await.prototype.componentDidMount = function () {
        this.subscribe(this.props.source);
    };
    Await.prototype.componentDidUpdate = function (prevProps) {
        var source = this.props.source;
        if (source !== prevProps.source) {
            this.setState({ pending: !!source, data: undefined });
            this.subscribe(source);
        }
    };
    Await.prototype.componentWillUnmount = function () {
        this.loadingId = {}; // ignores pending subscriptions
    };
    Await.prototype.subscribe = function (source) {
        var _this = this;
        var loadingId = this.loadingId = {};
        if (!source) {
            return;
        }
        source.then(function (data) {
            if (loadingId === _this.loadingId) {
                _this.setState({ pending: false, error: undefined, data: data, retainedData: data });
            }
        }, function (error) {
            if (loadingId === _this.loadingId) {
                _this.setState({ pending: false, error: error, data: undefined });
            }
        });
    };
    Await.prototype.render = function () {
        return this.props.children(this.state);
    };
    return Await;
}(React.PureComponent));
export { Await };
