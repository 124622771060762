import { action } from 'mobx';
import { readCookie, writeCookie } from '../utils/cookies';
import { DEFAULT_LOCALE, LOCALES } from './';
var LANGAGECOOKIE = 'LangageCookie';
export function setLocale(store, locale) {
    setLangageCookie(locale);
    applyLocale(store, locale);
}
export var applyLocale = action(function (store, locale) {
    if (document.documentElement) {
        document.documentElement.setAttribute('lang', locale);
    }
    store.i18n = LOCALES[locale] || DEFAULT_LOCALE;
});
export function initLocale(store) {
    var locale = detectLocale() || 'nl';
    applyLocale(store, locale);
}
function setLangageCookie(locale) {
    writeCookie(LANGAGECOOKIE, locale);
}
function getLangageFromCookie() {
    return readCookie(LANGAGECOOKIE);
}
function detectLocale() {
    var langFromCookie = getLangageFromCookie();
    if (langFromCookie) {
        return langFromCookie;
    }
    return 'nl';
    /*
    const languages: Array<string | undefined> = [].concat(
        (navigator as any).languages,
        (navigator as any).language,
        (navigator as any).browserLanguage,
        (navigator as any).userLanguage,
        (navigator as any).systemLanguage,
    );

    for (const lang of languages) {
        const shortened = lang && lang.split('-', 1)[0];

        if (shortened && LOCALES[shortened]) {
            return shortened;
        }
    }
    */
}
