import { __decorate } from "tslib";
import { createHashHistory } from 'history';
import { observable } from 'mobx';
import { DEFAULT_LOCALE } from '../i18n';
import { LoginResponse } from '../auth/types';
import { getStartOfWeek } from '../utils/date';
var Store = /** @class */ (function () {
    function Store() {
        this.loginStatus = LoginResponse.Reconnect;
        this.i18n = DEFAULT_LOCALE;
        this.history = createHashHistory();
        this.activeScreen = null;
        this.sideBarCollapsed = true;
        this.scheduleFilter = false;
        this.scheduleToday = new Date();
        this.scheduleStartOfWeek = getStartOfWeek(this.scheduleToday);
        this.scheduleDate = this.scheduleToday;
        this.customConfirmCallback = undefined;
        this.customConfirmTitle = undefined;
        this.confirmUnload = false;
        this.confirmUnloadMessage = '';
        this.mergeAccount = { status: false, accessCode: undefined };
        this.dashboardPeriod = 7;
        this.materialToast = false;
        this.FilterSearch = '';
        this.unreadChatRoom = null;
        this.rememberLastParameters = true;
    }
    __decorate([
        observable
    ], Store.prototype, "loginStatus", void 0);
    __decorate([
        observable
    ], Store.prototype, "i18n", void 0);
    __decorate([
        observable
    ], Store.prototype, "history", void 0);
    __decorate([
        observable
    ], Store.prototype, "activeScreen", void 0);
    __decorate([
        observable
    ], Store.prototype, "sideBarCollapsed", void 0);
    __decorate([
        observable
    ], Store.prototype, "scheduleFilter", void 0);
    __decorate([
        observable
    ], Store.prototype, "scheduleToday", void 0);
    __decorate([
        observable
    ], Store.prototype, "scheduleStartOfWeek", void 0);
    __decorate([
        observable
    ], Store.prototype, "scheduleDate", void 0);
    __decorate([
        observable
    ], Store.prototype, "customConfirmCallback", void 0);
    __decorate([
        observable
    ], Store.prototype, "customConfirmTitle", void 0);
    __decorate([
        observable
    ], Store.prototype, "confirmUnload", void 0);
    __decorate([
        observable
    ], Store.prototype, "confirmUnloadMessage", void 0);
    __decorate([
        observable
    ], Store.prototype, "mergeAccount", void 0);
    __decorate([
        observable
    ], Store.prototype, "dashboardPeriod", void 0);
    __decorate([
        observable
    ], Store.prototype, "confirmHistory", void 0);
    __decorate([
        observable
    ], Store.prototype, "materialToast", void 0);
    __decorate([
        observable
    ], Store.prototype, "FilterSearch", void 0);
    __decorate([
        observable
    ], Store.prototype, "unreadChatRoom", void 0);
    __decorate([
        observable
    ], Store.prototype, "rememberLastParameters", void 0);
    return Store;
}());
export { Store };
