import { __decorate, __extends } from "tslib";
import { inject, observer } from 'mobx-react';
import React from 'react';
// import {pushHistory} from '../router/actions';
// import {Route} from '../router/routes';
import S from './claims.styl';
var ClaimsView = /** @class */ (function (_super) {
    __extends(ClaimsView, _super);
    function ClaimsView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClaimsView.prototype.render = function () {
        var props = this.props;
        var i18n = props.store.i18n;
        return (React.createElement("div", { className: S.claimsView },
            React.createElement("h1", { className: S.claimsTitle }, i18n.claimsTitle),
            React.createElement("hr", { className: S.claimsSeparator }),
            React.createElement("div", { className: S.claimsMainBox })));
    };
    ClaimsView = __decorate([
        inject('store'),
        observer
    ], ClaimsView);
    return ClaimsView;
}(React.Component));
export { ClaimsView };
